

.HomePage-Parent{
  
    gap: 20px; 
    width: 100%;
    position: relative;
    background-color: #7db14e;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.6rem 0 0.687rem;
    gap: 10.75rem;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 1.25rem;
    color: #fff;
    font-family: Jost;
    
}





