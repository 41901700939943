.how-to-buy-wrapper {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.how-to-buy1 {
    font-family: "Cherry Bomb";
    font-size: 64px;
    font-weight: 400;
    line-height: 64px;
    text-align: left;

}

.buy-content-container {
    background: #00000080;
    border: 2px solid #FFFEF8E5;
    box-shadow: 0px 0px 50px 20px #FFFEF82E;


    width: 411px;
    height: 178px;
    top: 1891px;
    left: 65px;
    padding: 20px 21px 20px 20px;
    border-radius: 0px 30px 0px 30px;
    border: 2px 0px 0px 0px;
    opacity: 0px;
    gap: 0px;


}



