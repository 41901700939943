body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.root{
  height: 100% !important;
}

@import url("https://fonts.googleapis.com/css2?family=Cherry+Bomb:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Blinker:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  /* justify-content: center;  */
  /* align-items: center; */
  width: 100%; /* Use 100% width for responsive design */
  max-width: 1920px; /* Set max-width for the container */
  
  margin: auto;
  /* height: 100vh;  */
}