.cage-container {
    width: 95%;
    height: 0; /* Set height to 0 initially */
    padding-top: 70%; /* Adjust this percentage to change the aspect ratio */
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
}

.meme-coin-img {
    width: 15%;
    position: absolute;
    transition: transform 2s ease-out;
}

.meme-cage-img {
    width: 100%;
    height: 100%; /* Ensure the cage image fills the container */
    position: absolute;
    z-index: 10;
    object-fit: cover; /* Ensures the image covers the available space without being distorted */
}
