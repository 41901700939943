/* about.css */
.about-container {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}



.about-image {
  /* max-width: 1435px !important;
  max-height: 854px !important; */

  /* max-width: 80em !important;
  max-height: 45em !important; */
  
  width: 80% !important;
  /* height: 90vh !important; */
}

.about-image-container{
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  width: 100% !important;
}

.about-title {
  font-family: "Cherry Bomb" !important;
  text-align: center !important;
  margin-top: 1em !important;
}

.about-text {

  font-family: "Blinker" !important;
  font-size: 1.1em ;
  font-weight: 600  !important;
  line-height: 1.4em ;
  text-align: center !important;

}
