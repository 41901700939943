.roadmap-wrapper {

    display: flex;
    justify-content: center;
    align-items: center;

}

.roadmap-text {
    font-family: "Cherry Bomb";
    font-weight: 400;
    font-size: 64px;
    font-weight: 400;
    line-height: 64px;


}


.roadmap-content-wrapper-flex{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    
}

